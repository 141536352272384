import React from 'react';
import Accent from '../Accent';
import StaticText, { StaticTextItem } from '../StaticText';
import styles from './styles.module.less';

interface IntroBlockProps {
   header: string;
   par?: string;
   id?: string;
   staticTextItems?: StaticTextItem[];
}

const IntroBlock = (props: IntroBlockProps) => {
   const { header, par, id = '', staticTextItems = [] } = props;

   if (header && par)
      return (
         <section className={styles.IntroBlock}>
            <div className={`${styles.inner} ${styles.absolute} ${styles.bg}`}>
               <h1 className={styles.introTitle}>{header}</h1>
               <Accent align="center" />
               <div className={styles.par}>{par}</div>
            </div>
         </section>
      );

   return (
      <section className={styles.IntroBlock}>
         <div className={`${styles.inner} ${styles.absolute} ${styles.bg}`}>
            <h1 className={styles.introTitle}>{header}</h1>
            <Accent align="center" />
            <StaticText id={id} items={staticTextItems} className={styles.introText} />
         </div>
      </section>
   );
};

export default IntroBlock;
